<script setup lang="ts">
import type { Comment } from '~/api/drupal'

defineProps<Comment>()
const { setParent } = useCommentForm()
</script>
<template>
  <article class="comment">
    <h3 class="name">{{ name }}</h3>
    <span class="date text-xsmall">{{ date }}</span>
    <div class="body" v-html="body" />
    <button class="link-default" @click="setParent(id)">{{ $t('news.detail.comments.replyCTA') }}</button>
    <div :id="`comment-${id}`" class="form" />
    <Comments v-if="children?.length" :items="children" />
  </article>
</template>
<style lang="scss" scoped>
.comment {
  @include fluid(padding-bottom, 16px, 24px);
  @include fluid(margin-bottom, 16px, 48px);
  border-bottom: 1px solid var(--c-line-light);
}

.name {
  margin-bottom: 0;
}
.date {
  display: block;
  margin-bottom: 16px;
}

.body {
  // & > :deep(*:last-child) {
  //   margin-bottom: 0;
  // }
}

.comments {
  @include fluid(margin-top, 16px, 32px);
  @include fluid(margin-left, 16px, 48px);
}
</style>
